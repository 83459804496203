.svg{
    img{
        display:none;
    }
    @media (max-width: 600px) {
        svg{
            display:none;
        }
        img{
            display:block;
        }
      }
}