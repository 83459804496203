.gri-box{
    display: flex;
    align-items: center;
    span{
        font-size: 1.4rem;
        font-weight: bold;
        margin-right: 5px;
    }
    .gri-inline{
        display: flex;
        list-style: none;
        margin:0;
        padding:0;        
        color:$orange;
        &:after{
            content:']';
            display: block;
            position: relative;
            font-size:1.7rem;
            color:$orange;
            margin-left: 2px;
            font-weight: bold;
        }
        &:before{
            content:'[';
            display: block;
            position: relative;
            font-size:1.7rem;
            color:$orange;
            margin-right: 2px;
            font-weight: bold;
        }
        li{
            font-size: 1.4rem;
            font-weight: bold;
            &:before{
                display:none;
            }
        }
    }
}