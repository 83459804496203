.slider {
  margin-top:20px;
  .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.6rem;
    margin: 4.5rem 0;
  }
  .owl-carousel button.owl-dot {
    display: block;
    height: 1.6rem;
    width: 1.6rem;
    background: $light-gray4;
    border-radius: 50%; 
    margin: 0 1rem;
    transition: .8s;
    &.active {
      height: 2.6rem;
      width: 2.6rem;
      background: $orange;
    }
  }
}