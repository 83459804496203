.breadcrumbs{
    margin-bottom:20px;
    @media (max-width: 1199px) {
        margin-top:110px;
    }
    .container-fluid{
    }
    &__wrapper{
      position: relative;
      z-index: 1;
        margin-left:-15px;
        ul{
            list-style:none;
            margin:0;
            padding:0;
            li{
                color:$black;
                text-decoration:none;
                font-size:1.6rem;
                font-weight: normal;
                float:left;
                &:after{
                    content:'>';
                    position: relative;
                    font-size: 1.6rem;
                    margin:0 5px;
                }
                a{
                    color:$black;
                    text-decoration:none;
                    font-size:1.6rem;
                    font-weight: normal;
                    transition: 0.3s;
                    &:hover{
                        color:$orange;
                    }
                }
                &:last-child{
                    font-size:1.6rem;
                    font-weight: bold;   
                    &:after{
                        content:'';
                    }             
                }
            }
        }
    }
    .c-gri{        
        display: flex;
        justify-content: flex-end;
        margin-top:-31px;
        padding-right: 26px;
        @media (max-width: 1440px) {
            margin-top:5px;
            padding-right: 8rem;
        }
        @media (max-width: 575px) {
            padding-right: 1rem;
        }
        &__wrapper{
            position: relative;
            display: flex;
            align-items: center;
            font-size:2.2rem;
            font-family: 'Titillium Web', sans-serif;
            font-weight:bold;            
            @media (max-width: 768px) {
                font-size:1.6rem;
                ul li a{
                    font-size:1.6rem !important;
                }
            }
            span{
                font-weight: bold;
                display: block;
            }
            ul{
                list-style:none;
                margin:0;
                padding:0;
                li{
                    float:left;
                    &:after{
                        content:',';
                        position: relative;
                        font-size: 1.6rem;
                        margin:0 2px;
                        line-height:0;
                    }
                    &:last-child{
                        &:after{
                            content:'...';
                        }             
                    }
                    a{
                        font-size:2.2rem;
                        font-family: 'Titillium Web', sans-serif;
                        font-weight:bold;
                    }
                }
                &#hiddenGriList{
                    background-color:$white;
                    position: absolute;
                    right: 24px;
                    top: 30px;
                    display:none;              
                    z-index: 9999;
                    &--active{          
                    }
                    li{
                        float:none;
                    }
                }
            }
        }
    }

}