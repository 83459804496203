.download {
  ul {
    list-style: none;
    & > li {
      label {
        font-size: 1.8rem;
        font-weight: 700;
      }
      ul.menu_level_1 {
        li {
          label {
            font-size: 1.6rem;
            font-weight: 400;
          }
        }
      }
    }
  }
  label {
    display: block;
    position: relative;
    padding-left: 3.5rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    font-size: 1.6rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      transition: .3s;
      &:checked ~ .checkmark {
        background-color: $orange;
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 2rem;
      width: 2rem;
      background-color: #eee;
      transition: .3s;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    &:hover input ~ .checkmark {
      background-color: $orange;
    }

  }

  label input:checked ~ .checkmark:after {
    display: block;
  }

  label .checkmark:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  button {
    margin: 4rem auto;
    height: 4rem;
    width: 16rem;
    border: 2px solid $orange;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $orange;
    text-transform: uppercase;
    transition: .4s;
    &:hover {
      background: $orange;
      color: white;
    }
  }
}