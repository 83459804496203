.numbers-in-box {
  border: 1px solid $orange;
  padding: 6rem 2rem;
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
  }
  &__item {
    width: 100%;
    padding: 0;
    text-align: center;
    margin-bottom: 4rem;
    @media (min-width: 767px) {
      width: 50%
    }
    @media (min-width: 992px) {
      width: 33.33%;
      margin-bottom: 0;
    }
  }
  &__accent-number {
    font-size: 7.2rem;
    line-height: 1;
    font-weight: 700;
    color: $orange;
    margin: 0;
    &:lang(en) {
      font-size: 5.8rem;
    }
  }
  &__unit {
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 700;
    color: $orange;
    margin-bottom: 2rem;
  }
  &__description {
    color: $dark-gray;
    margin: 0;
  }
  &__label {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 700;
    color: $dark-gray;
    margin: 0;
  }
  .owl-dots {
    position: absolute;
    bottom: -16rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.6rem;
    margin: 4.5rem 0;
  }
  .owl-carousel button.owl-dot {
    display: block;
    height: 1.6rem;
    width: 1.6rem;
    background: $light-gray4;
    border-radius: 50%; 
    margin: 0 1rem;
    transition: .8s;
    &.active {
      height: 2.6rem;
      width: 2.6rem;
      background: $orange;
    }
  }
}