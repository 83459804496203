.highlight{
    background-color:#ededed;
    color:#363636;
    font-weight: bold;
    padding:40px 97px 40px 97px;
    position: relative;
    @media (max-width: 768px) {
        padding:40px 30px 40px 97px; 
    }
    @media (max-width:576px){
        padding:40px 30px 40px 30px;
    }
    &:before{
        background-color:$orange;
        position:absolute;
        content:'';
        width:62px;
        height:20px;
        left: 15px;
        @media (max-width:576px){
            width: 100%;
            height: 20px;
            left: 0;
            top: 0;
            display: block;
        }
    }
    p{
        margin:0;
        padding:0;
        a{
            display:block;
        }
    }
}