.accordion {
  margin: 2rem 0;
  background: $white;
  &__item {
    margin-bottom: 1rem;
  }
  &__header {
    border-bottom: 1px solid $light-gray2;
  }
  &__btn {
    position: relative;
    color: $orange;
    width: 100%;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1rem 4.5rem 1rem 3rem;
    text-align: left;
    @media (min-width: 768px) {
      font-size: 1.9rem;
      line-height: 3rem;
      padding: 1.5rem 4.5rem 1.5rem 4rem;
    }
    @media (min-width: 1400px) {
      font-size: 2.4rem;
      line-height: 3.6rem;
      padding: 1.5rem 4.5rem 1.5rem 6.5rem;
    }
    &::before {
      content: '';
      display: block;
      height: calc(100% + 1px);
      width: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      background: $orange;
    }
    &::after {
      content: '-';
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      font-size: 2.8rem;
      @media (min-width: 768px) {
        font-size: 3.4rem;
      }
    }
    &.collapsed {
      color: $black;
      &::after {
        content: '+';
      }
    }
  }
  &__content {
    padding: 5rem 0;
  }
}