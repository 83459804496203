.ceo {
  margin: 4rem 0;
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 4rem;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
  &__text {
    font-family: 'Open Sans', sans-serif;
    color: $black;
    h4 {
      font-size: 2.4rem;
      font-weight: 700;
    }
    p {
      b {
        font-weight: bold;
      }
    }
  }
}

.administration {
  margin: 4rem 0;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    margin: 12rem 0 6rem 0;
  }
  &__management,
  &__directors {
    text-align: center;
    width: 100%;
    @media (min-width: 768px) {
      width: 50%;
    }
    div{
      height: 10rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      h4 {
        font-size: 2.4rem;
        font-weight: 700;
        color: $white;
      }
    }
    p {
      margin: 2rem 0;
      b {
        font-weight: bold;
      }
    }
  }
  &__management {
    div {
      background: $light-gray5;
    }
  }
  &__directors {
    div {
      background: $orange;
    }
  }
}