.popup {
  display: none;
  width: 96%;
  background: $light-gray3;
  border-left: .8rem solid $orange;
  padding: 5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  overflow: auto;
  max-height: 96vh;
  @media (min-width: 992px) {
    width: 94rem;
  }
  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  &__x {
    width: 2.6rem; 
    height: 2.6rem;
    path {
      fill-rule: evenodd; 
      fill: $orange;
    }
  }
  &__header {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 4rem;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    div[data-clipboard-container]{
      width:100%;
      form{
        width:100%;
        display:flex;
        justify-content: flex-end;
        button{
          background-color:$orange;
          border-radius:5px;
          color:$white;
          padding:10px 30px;
          margin-right:30px;
          font-size: 1.4rem;
        }
      }
    }
    [data-clipboard-container-empty]{
      font-size:1.4rem;
    }
  }
  p {
    font-size: 1.6rem;
    margin: 2rem 0;
    b,
    strong {
      font-weight: 700;
    }
  }

  ul {
    font-family: 'Open Sans', sans-serif;
    list-style-type: none;
    margin: 0;
    padding: 0rem 0 2rem 2rem;
    li {
      font-size: 1.6rem;
      line-height: 2.4rem;
      &::before {
        content: "\25CF";
        font-size: 1.6rem;
        display: inline-block;
        width: 2rem;
        margin-left: -2rem;
        color: $orange;
      }
      a{
        text-decoration:none;
      }
    }
  }

  &__left {
    width: 100%;
    @media (min-width: 768px) {
      width: 50%;
      padding-right: 3rem;
    }
  }
  &__right {
    width: 100%;
    @media (min-width: 768px) {
      width: 50%;
      padding-left: 3rem;
    }
  }
}

.mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($white, .7);
  z-index: 9999999;
}