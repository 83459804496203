.gc-rule{
    &__wrapper{
        display: flex; 
        justify-content: flex-end;
        align-items: center;
        &__text{
            font-family: 'Titillium Web', sans-serif;
            font-weight:normal;
            font-size: 1.6rem;
            margin-right:20px;
            strong{
                font-weight:bold;
            }
        }        
        &__img{
            margin:0;
        }
    } 
}