.table{
    border:none;
    .container{
        padding:0;
    }
    &__wrapper{
        @media (max-width: 768px){
            margin: 0 auto;
            display: block;
            overflow-x: scroll;
            table{
                width: 600px;
            }
        }
    }
    &__header{
        border-bottom:3px solid $orange;
        th{
            padding:10px 15px 25px 15px;
            color:$orange;
            font-weight:bold;
            font-size: 1.6rem;
            border:none;
            vertical-align: middle;
            &:last-child{
                text-align:right;
            }
        }
    }
    tr{
        td{
            padding:20px 15px;
            font-size: 1.6rem;
            vertical-align: middle;
            border-bottom:1px solid $light-gray;
            &:first-child{
                width:75%;
            }
            &:last-child{
                text-align:right;
            }
        }
    }
}