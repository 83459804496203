.content {
  margin-bottom: 4rem;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 77vw;
  }
  @media (min-width: 992px) {
    max-width: 84vw;
  }
  @media (min-width: 1200px) {
    padding-right: 11rem;
  }
  @media (min-width: 1600px) {
    padding-right: 0;
  }
  ol{
    font-family: 'Open Sans', sans-serif;
    list-style-type: lower-latin;
    margin: 0;
    padding: 0 0 2rem 4rem;
    li {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  ul {
    font-family: 'Open Sans', sans-serif;
    list-style-type: none;
    margin: 0;
    padding: 0rem 0 2rem 2rem;
    li {
      font-size: 1.6rem;
      line-height: 2.4rem;
      &::before {
        content: "\25CF";
        font-size: 1.6rem;
        display: inline-block;
        width: 2rem;
        margin-left: -2rem;
        color: $orange;
      }
    }
  }
  h2 {
    font-size: 4.4rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 4rem 0;
    padding-top: 2rem;
    @media (min-width: 768px) {
      font-size: 7.4rem;
      padding-top: 5rem;
      margin: 7rem 0;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 10rem;
      display: block;
      border-top: .7rem solid $orange;
      margin-bottom: 5rem;
    }
  }
}

.component {
  margin: 2.5rem 0;
}