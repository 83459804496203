.debug{
    .component{
        h3{
            text-transform: uppercase;
            border-bottom:2px solid $orange;
            padding:0;
            margin-bottom:15px;
        }
        .preview{
        }
        .code{
            padding: 1em;
            background: #cccccc;
            font-size: 1.2rem;
        }
        .doc{
            margin-bottom: 2em;
            .doc-box{
                padding-left:10px;
                font-weight:normal;                
                padding: 1em;
                background: #cccccc;
                font-size: 1.2rem;
            }
        }
        .others{
            a{
                font-size: 1.4rem;
                font-weight: bold;
            }
        }
    }
}