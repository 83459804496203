.text-with-image{
    .col-12{
        padding:0;
    }
    &__image-wrapper--right img{
        padding-left:50px;
        @media (max-width: 998px){
            float:none !important;
            padding:0;
            margin-bottom:15px;
            width:100%;
        }
    }
    &__image-wrapper--left img{
        padding-right:50px;
        @media (max-width: 998px){
            float:none !important;
            padding:0;
            margin-bottom:15px;
            width:100%;
        }
    }
}