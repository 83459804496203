.toolbar {
  display: none;
  @media (min-width: 767px) {
    position: fixed;
    top: 150px;
    right: 2rem;
    display: block;
    width: 6rem;
    z-index: 5;
    ul{
      padding: 0;
      li{
        list-style:none;
        border-radius:50%;
        width:55px;
        height:55px;    
        background-color:$white;    
        display: flex;  
        justify-content: center; 
        align-content: center;
        margin-bottom: 10px;
        &:not(.np-btn){
          border:2px solid $light-gray3;
        }
        button, a{
          position: relative;
          width:55px;
          margin:0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          div{
            position: absolute;
            left: -161px;
            font-size: 1.4rem;
            font-weight: bold;
            width: 150px;
            text-align: right;
            height: 55px;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            opacity:0;
            transition: .4s;
            z-index:-10;
            visibility: hidden;
          }
          &:hover{
            div{
              opacity:1;
              z-index:100;
              visibility: visible;
            }
          }
        }
        &.np-btn{
          background-color:$orange;
          img{
            &.rotated{
              transform:rotate(180deg);
            }
          }    
        }
      }
    }
  }
  @media (min-width: 1680px) {
    right: 2rem;
  }
  @media (max-width: 1200px) {
    top: 10rem;
  }
}