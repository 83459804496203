.video {
  margin: 4rem 0;
  &__content {
    position: relative;
    margin-bottom: 3.5rem;
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__description {
    font-size: 1.6rem;
    font-weight: 700;
    color: $black;
  }
  &__play {
    width: 14rem;
    @media (min-width: 768px) {
      width: 24rem;
    }
  }
}