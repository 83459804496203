header {
  position: fixed;
  top: 0;
  left: 0;
  height: 8rem; 
  width: 100%;  
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  z-index: 9999999;
  padding-right: 3rem;
  @media (min-width: 1200px) {
    align-items: flex-start;
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 0;
    height: 100vh;
    width: 39rem;
  }
}

.logo {
  display: flex;
  @media (min-width: 1200px) {
    display: block;
    margin-top: 1.5rem;
  }
  &__img {
    width: 11rem;
    margin-left: 2rem;
    @media (min-width: 576px) {
      margin: 0 2rem;
    }
    @media (min-width: 1200px) {
      width: 17rem;
      margin-right: 0;
    }
  }
  &__description {
    display: none;
    @media (min-width: 576px) {
      display: flex;
      font-family: 'Titillium Web', sans-serif;
      font-size: 2.4rem;
      font-weight: 600;
      color: $gray;
    }
    @media (min-width: 1200px) {
      margin-bottom: 2rem;
    }
    &--orange {
      color: $orange;
      margin-left: .2rem;
    }
  }
  p {
    margin: 2rem 0 0 2rem;
  }
}

.nav {
  display: none;
  align-items: center;
  position: absolute;
  left: 0;
  top: 8rem;
  width: 100%;
  height: calc(100vh - 8rem);
  padding-top: 2rem;
  background: $white;
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  @media (min-width: 1200px) {
    position: relative;
    top: initial;
    left: initial;
    height: calc(100vh - 20rem);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    padding-top: 0;
  }
  &__list {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    width: 100%;
    padding: 0 5vw;
    @media (min-width: 480px) {
      padding: 0 10vw;
    }
    @media (min-width: 1200px) {
      padding: 0;
    }
  }
  &__sublist {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    display: none;
    margin: 1.2rem 0;
    padding: 0;
  }
  &__item {
    color: $black;
    font-size: 1.4rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: 600;
    padding-right: 1rem;
    &::before {
      content: '';
    }
    @media (min-width: 768px) {
      margin-bottom: 2rem;
      font-size: 1.9rem;
    }
    &:first-child {
      text-transform: none;
      margin-bottom: 2rem;
      @media (min-width: 1200px) {
        margin-bottom: 4rem;
      }
      &::before {
        content: '';
      }
    }
    &--bottom {
      margin-bottom: 1rem;
    }
  }
  &__subitem {
    font-size: 1.3rem;
    margin-bottom: .8rem;
    font-weight: 600;
    &::before {
      content: '';
    }
    @media (min-width: 768px) {
      font-size: 1.8rem;
      text-transform: none;
      margin-bottom: 1.2rem;
      color: $black;
    }
  }
  &__level {
    position: relative;
    cursor: pointer;
    padding-left: 2rem;
    transition: .3s;
    &::before {
      content: '+';
      font-size: 2.1rem;
      font-weight: 600;
      position: absolute;
      top: -.8rem;
      left: 0rem;
      @media (min-width: 768px){
        font-size: 2.6rem;
      }
    }
    &:hover {
      color: $orange;
    }
    &.active {
      &::before {
        content: '-';
      }
    }  
  }
  &__svg {
    display: inline-block;
    width: 2rem;
    height: 2.5rem;
    margin-right: .5rem;
    margin-top: -1rem;
    transition: .3s;
    path {
      stroke: $black;
      stroke-width: 1px;
      fill: $black;
    }
  }
  &__link {
    color: $black;
    text-decoration: none;
    transition: .3s;
    &:hover,
    &.active {
      color: $orange;
      text-decoration: none;
      .nav__svg {
        path{
          stroke: $orange;
          fill: $orange;
        }
      }
    }
    &--icon {
      display: flex;
      align-items: center;
      margin-left: -1rem;
      text-transform: none;
    }
  }
  &__icon {
    width: 4rem;
    margin-right: 0.5rem;
    display: inline-flex;
    justify-content: center;
    @media (min-width: 768px) {
      width: 6rem;
    }
  }
  &__img {
    display: block;
    width: 3rem;
    height: 3rem;
    @media (min-width: 768px) {
      width: 4rem;
      height: 4rem;
    }
  }
}

.hamburger {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer; 
  &:focus {
    outline: none;
  }
  @media (min-width: 1200px) {
    display: none;
  }
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    width: 35px;
    height: 30px;
    &:focus {
      outline: none;
    }
  }
  &__bars {
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: $black;
    transition: transform 220ms ease-in-out;
    &::before,
    &::after {
      display: block;
      position: absolute;
      width: 35px;
      height: 2px;
      background-color: $black;
      content: '';
    }
    &::before {
      top: -12px;
      transition: top 100ms 250ms ease-in, 
      transform 220ms ease-in-out; 
    }
    &::after {
      bottom: -12px;
      transition: bottom 100ms 250ms ease-in, 
      transform 220ms ease-in-out;
    }
  }
  &--active {
    .hamburger__bars {
      transform: rotate(225deg);
      transition: transform 220ms 120ms ease-in-out;
      &:before {
        top: 0;
        transition: top 100ms ease-out; 
      }
      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 100ms ease-out, 
        transform 220ms 120ms ease-in-out; 
      }
    }
  }
}
