.businessModel{
	&__content{
		margin-top: 40px;
		&--img{
			width: 100%;
		}
.slider{
	&__options{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		margin: 30px 0;
		button{
			display: none;
			padding: 5px;
			margin: 5px;
			font-size: 1.4rem;
			text-align: center;
			font-weight: 700;
			width: calc(50% - 10px);
			text-transform: uppercase;
			color: $orange;
			min-height: 44px;
			cursor: pointer;
			background: transparent;
			border: 1px solid $orange;
			@media only screen and (max-width: 576px) {
				display: block;
			}
			@media only screen and (max-width: 480px) {
				width: 100%;
			}
			&:hover{
				color: $white;
				background: $orange;
			}
			&.active{
				color: $white;
				background: $orange;
			}
		}
	}
	&__text{
		&--top{
			p{
				font-size: 1.6rem;
				@media only screen and (max-width: 576px) {
					font-size: 1.4rem;
				}
			}
			span{
				font-size: 1.6rem;
				font-weight: 700;
				display: block;
				font-family: 'Open Sans';
				text-transform: none;
				margin: 8px 0;
				@media only screen and (max-width: 576px) {
					font-size: 1.4rem;
				}
			}
			span:first-child{
				width: 50%;
			}
		}
		&--bottom{
			p{
				color: $orange;
				font-size: 1.6rem;
				font-weight: 700;
				margin: 8px 0;
				@media only screen and (max-width: 576px) {
					font-size: 1.4rem;
				}
			}
		}
	}
	&__dotted{
		padding: 30px 50px;
		min-height: 295px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media only screen and (max-width: 576px) {
			padding: 10px;
			min-height: auto;
		}
		&--silver{
			border: 3px solid $light-gray5;
			border-bottom: none;
			@media only screen and (max-width: 991px) {
				min-height: 343px;
			}
			@media only screen and (max-width: 767px) {
				min-height: 392px;
			}
			@media only screen and (max-width: 576px) {
				min-height: auto;
			}
		}
		&--orange{
			border: 3px solid $orange;
			border-top: none;
		}
		div{
			display: flex;
		}
	}
	&__header,
	&__bottom{
		width: 100%;
		text-align: center;
		font-size: 3rem;
		font-family: "Open Sans";
		font-weight: bold;
		text-transform: uppercase;
		line-height: 1.6;
		text-align: center; 
		color: $white;
		padding: 25px 0;
		&--silver{
			background: #a6a6a6;
		}
		&--orange{
			background: $orange;
    }
    h4 {
      font-size: 3rem;
      font-weight: 700;
      font-family: 'Open Sans', sans-serif;
    }
	}
	&__graphics{
		display: flex;
		margin: 60px 0 40px 0;
		@media only screen and (max-width: 576px) {
			flex-wrap: wrap;
		}
		#surowce,
		#ludzie,
		#finanse{
			.cls-1, .cls-5 {
				fill: none;
			 }
			.cls-2 {
				clip-path: url(#clip-path);
			 }
			.cls-3, .cls-4 {
				fill: $light-gray5;
				@media only screen and (max-width: 576px) {
					fill: $orange;
				}
			 }
			.cls-4 {
				fill-rule: evenodd;
			 }
			.cls-5 {
				stroke: $light-gray5;
				stroke-miterlimit: 22.926;
				stroke-width: 0.567px;
				@media only screen and (max-width: 576px) {
					stroke: $orange;
				}
			 }
		}
		#surowce.active,
		#ludzie.active,
		#finanse.active{
			.cls-1, .cls-5 {
				fill: none;
			 }
			.cls-2 {
				clip-path: url(#clip-path);
			 }
			.cls-3, .cls-4 {
				fill: $orange;
			 }
			.cls-4 {
				fill-rule: evenodd;
			 }
			.cls-5 {
				stroke: $orange;
				stroke-miterlimit: 22.926;
				stroke-width: 0.567px;
			 }
		}
		#aktywa{
			.cls-1, .cls-4, .cls-5 {
				fill: none;
			}
			.cls-2 {
				clip-path: url(#clip-path);
			}
			.cls-3 {
				fill: $light-gray5;
				@media only screen and (max-width: 576px) {
					fill: $orange;
				}
			}
			.cls-4, .cls-5 {
				stroke: $light-gray5;
				stroke-width: 0.567px;
				@media only screen and (max-width: 576px) {
					stroke: $orange;
				}
			}
			.cls-4 {
				stroke-miterlimit: 22.926;
			}
			.cls-5 {
				stroke-linecap: round;
				stroke-linejoin: round;
			}
		}
		#aktywa.active{
			.cls-1, .cls-4, .cls-5 {
				fill: none;
			}
			.cls-2 {
				clip-path: url(#clip-path);
			}
			.cls-3 {
				fill: $orange;
			}
			.cls-4, .cls-5 {
				stroke: $orange;
				stroke-width: 0.567px;
			}
			.cls-4 {
				stroke-miterlimit: 22.926;
			}
			.cls-5 {
				stroke-linecap: round;
				stroke-linejoin: round;
			}
		}
		&--one,
		&--two,
		&--three,
		&--four{
			padding: 10px;
			width: 25%;
			position: relative;
			@media only screen and (max-width: 576px) {
				width: 100%;
				display: none;
				padding: 10%;
			}
			svg{
				cursor: pointer;
			}
			span{
				margin-top:10px;
				color: $orange;
				font-size: 1.6rem;
				font-weight: 700;
				font-family: 'Open Sans';
				text-transform: none;
				text-align: center;
				transition: 0.4s;
				display: block;
				@media only screen and (max-width: 576px) {
					display: none;
				}
			}
			.arrowTop{
				position: absolute;
				top: -60px;
				left: 0;
				right: 0;
				margin: auto;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 23px 12.5px 0 12.5px;
				border-color: $light-gray5 transparent transparent transparent;
				opacity: 0;
				@media only screen and (max-width: 576px) {
					opacity: 1;
				}
			}
			.arrowBottom{
				position: absolute;
				bottom: -40px;
				left: 0;
				right: 0;
				margin: auto;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 12.5px 23px 12.5px;
				border-color: transparent transparent $orange transparent;
				opacity: 0;
				@media only screen and (max-width: 576px) {
					opacity: 1;
				}
			}
		}
		&--one{
			@media only screen and (max-width: 576px) {
				display: block;
			}
			.arrowTop,
			.arrowBottom{
				opacity: 1;
			}
		}
		.slide{
			span{
				opacity: 0;
			}
			&.slider__graphics--one{
				span{
					opacity: 1;
				}
			}
		}
	}
}
	}
}