.orange-box {
  background: $orange;
  color: $white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  padding: 3rem 2rem;
  @media (min-width: 768px) {
    padding: 3rem 6rem 3rem 4rem;
  }
  &__title {
    font-size: 2.4rem;
  }
  &__number {
    white-space: nowrap;
    font-size: 4.4rem;
    line-height: 4.4rem;
    @media (min-width: 768px) {
      font-size: 6.4rem;
      line-height: 6.4rem;
      margin-right: 4rem;
    }
    @media (min-width: 992px) {
      font-size: 9.4rem;
      line-height: 9.4rem;
      margin-right: 8rem;
    }
  }
  &__unit {
    font-size: 3rem;
    @media (min-width: 768px) {
      font-size: 4.4rem;
    }
  }
  &__description {
    font-size: 1.5rem;
    @media (min-width: 768px) {
      font-size: 1.8rem;
    }
  }
  &__content {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }
}