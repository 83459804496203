progress {
  display: none;
  position: fixed;
  left: 0;
  z-index: 998;
  height: 1.3rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: $dark-gray;
  color: $orange;
  top: 8rem;
  width: 100%;
  @media (min-width: 1200px) {
    top: calc(50% - 0.5rem);
    width: 101vh;
    transform: rotate(90deg);
  }

  &::-webkit-progress-bar {
    background-color: $dark-gray;
  }

  &::-webkit-progress-value {
    background-color: $orange;
  }

  &::-moz-progress-bar {
    background-color: $orange;
  }

}