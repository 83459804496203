.quote{
    background-color:#ededed;
    color:#363636;
    font-weight: bold;
    display: flex;
    @media (max-width: 576px) {
        display:block;
    }
    &__mark{
        position: relative;
        background-color:$orange;
        width:75px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 576px) {
            display:block;
            padding:15px;
            width:100%;
        }
    }
    .container{
        padding:40px 97px 40px 50px;
        @media (max-width: 768px) {
            padding:20px 15px 20px 30px; 
        }
        p{
            margin:0;
            padding:0;
            font-style:italic;
            a{
                display:block;
            }
        }
    }
}