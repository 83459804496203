.box {
  &__list {
    margin: 4.5rem 0;
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    position: relative;
    width: 100%;
    border-bottom: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 25rem;
    padding-bottom: 3rem;
    &:last-child {
      border-bottom: none;
    }
    @media (min-width: 576px) {
      width: 50%;
      border-right: 1px solid $light-gray;
    }
    @media (min-width: 992px) {
      width: 33.33%;
    }
    @media (min-width: 1200px) {
      width: 50%;
    }
    @media (min-width: 1350px) {
      width: 33.33%;
    }

  }
  &__icon {
    margin: 3.5rem 0;
    height: 8rem;
  }
  &__header {
    font-size: 1.8rem;
    font-weight: 700;
    color: $black;
    text-align: center;
    padding: 0 2rem;
  }
  &__text {
    font-size: 1.8rem;
    font-weight: 700;
    color: $black;
    margin: 0;
    line-height: 1.3;
    padding: 0 4rem;
    text-align: center;
    &--orange {
      color: $orange;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.5rem;
    width: 5.5rem;
    background: $orange;
    color: $white;
    font-size: 3.5rem;
    font-weight: 700;
    position: absolute;
    bottom: -1px;
    right: -1px;
  }
}