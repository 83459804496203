.text-and-image{
    .col-12{
        display:flex;
        flex-direction: row;       
        padding:0; 
        @media (max-width: 850px) {
            flex-direction: column;
            &.border-activated{
                .text-and-image__wrapper{
                    &--left{
                        padding:0;
                        flex-direction: row;
                        justify-content:start;
                        width:100% !important;
                        img{
                            text-align: center;
                            display: block;
                            margin: 0 auto;
                            width:auto;
                        }
                    }
                    &--border{
                        border:1px solid $orange;
                        margin:15px 0;
                    }
                    &--right{
                        padding:0 !important;                        
                        width:100% !important;
                    }
                }
            }
            &.reverse-activated{
                flex-direction: column !important;;
                .text-and-image__wrapper{
                    &--left{
                        padding:0;
                        flex-direction: row;
                        justify-content:start;
                        margin-bottom:15px;
                    }
                    &--right{
                        padding:0 !important;
                    }
                }
            }
            .text-and-image__wrapper{
                &--left{
                    width:100% !important;
                    padding-left:0;
                    img{
                        text-align: center;
                        display: block;
                        margin: 0 auto;
                        width:auto;
                    }
                }
                &--right{
                    width:100% !important;
                }
            }
        }
        &.reverse-activated{
            flex-direction: row-reverse;
            .text-and-image__wrapper{
                &--left{
                    
                }
                &--right{
                    padding-right: 30px;
                    padding-left:0;
                }
            }
        }
        &.border-activated{
            .text-and-image__wrapper{
                &--border{
                    border:1px solid $orange;
                }
                &--left{
                    padding-right:15px;
                }
                &--right{
                    padding-left:15px;
                }
            }
            &.reverse-activated{
                .text-and-image__wrapper{
                    &--right{
                        padding-left:0px;
                        padding-right:15px;
                    }
                    &--left{
                        padding-left:15px;
                    }
                }
            }
        }
        .text-and-image__wrapper{
            &--left{
                display: flex;
                flex-direction: column;
                justify-content: center;
                //width:100%;
                img{
                    //width:100%;
                }
            }
            &--right{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 30px;
                padding-top:15px;
                padding-bottom:15px;
                //width:50%;
                p:last-child{
                    margin:0;
                }
            }
        }
    }
    
}