.cookies {
    background-color: rgba($black, 0.95);
    height: auto;
    width: 100%;
    z-index: 2147483647;
    position: fixed;
    bottom: 0;
    color: $white;
    display: flex;
    padding: 1em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform-origin: bottom;
    transform: scale(1, 0);
    transition: all 200ms ease;   
    
    p {
        font-size: 1.2rem;
        line-height: 1.1;
    }

    a {
        color: $white;
        text-decoration: none;
    }

    a:hover, button:hover {
        color: $orange;
    }

    &__btn {
        all: unset;
        font-size: 1.2rem;
        text-align: center;
        text-decoration: underline;
        display: block;
        padding: 0.5em 0;
    }
}
