*{
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{ 
  font-size: 50%; 
  @media (min-height: 620px) {
    font-size: 50%; 
  }
  @media (min-height: 720px) {
    font-size: 56.25%;
  }
  @media (min-height: 820px) {
    font-size: 62.5%; 
  }
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: $black;
}

main {
  margin-top: 8rem;
  @media (min-width: 1200px) {
    width: calc(100% - 39rem);
    margin-left: 39rem;
  }
}

.container {
  @media (min-width: 1200px) {
    margin-left: 0;
  }
  &__main{
    margin-top:60px;
  }
}

.banner{
  img{
    
  }
}

a{
  text-decoration:underline;
  color:$orange;
  transition: 0.3s;
  &:hover{
    color:$black;
  }
}

button {
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

h1 {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

h2 {
  position: relative;
  font-family: 'Titillium Web', sans-serif;
  font-size: 3.4rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 4rem 0;
  padding-top: 2rem;
  @media (min-width: 480px) {
    font-size: 4.4rem;
  }
  @media (min-width: 768px) {
    font-size: 7.4rem;
    padding-top: 5rem;
    margin: 7rem 0;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    display: block;
    border-top: .7rem solid $orange;
    margin-bottom: 5rem;
  }
}

h3 {
  font-family: 'Titillium Web', sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  padding: 2rem 0;
}

p {
  font-size: 1.6rem;
  padding: 0;
  line-height:2.4rem;
  strong{
    font-weight: bold;
  }
}

span{
  strong{
    font-weight: bold
  }
}

.component {
  margin: 2.5rem 0;
}

.only-pdf {
  display: none;
}

#skipLinks{
  position:fixed;
  right:0;
}

.search-results{
  ul li p{
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin-left: 5px;
  }
}

#form{
  .form__search{
    width: 50%;
    height: 43px;
    font-size: 2rem;
    padding: 5px 10px;
  }
  #submit{
    color:$white;
    background-color:$orange;
    font-size:1.6rem;
    margin-left:40px;
    border:none;
    padding:10px 40px;
    font-weight: bold;
  }
}

div[data-chart]{
  margin-top:60px;
  margin-bottom:60px;
}