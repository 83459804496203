.body--home{
    main{
        margin-top:0;
        overflow: hidden;
        text-align: center; 
        position: relative;
        height: 100vh;
        
    }
    
    video{
        display: inline-block;
        position: relative;  

        min-width: 100%;
        min-height: 100vh;
        right: 0;
        bottom: 0;

    }
    .video_content{
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        
        &__block{
            position: relative;
            color: $white;
            text-align: left;
            z-index: 99;
            width: 100%;
            height: 100%;
            
            &__box{
                position: absolute;
                bottom: 0;
                width: 42rem;
                right: 0;
                padding-right: 8rem;
                @media (min-height: 620px) {
                  bottom: 6rem;
                }
                a{
                    display: block;
                    color: $orange;
                    text-decoration: none;
                    font-size: 2.1rem;
                    margin-bottom: 6rem;
                    font-weight: bold;
                    &:hover{
                        color: $orange;
                        text-decoration: underline;
                    }
                    img{
                        width:12%;
                    }
                }
                &__header{
                    font-size: 4.4rem;
                }
                &__text{
                    font-size: 1.7rem;
                }
                &__list{
                    width: 100%;
                    display: inline-block;
                    font-size: 2.4rem;
                    list-style: none;
                    line-height: 7.2rem;
                    padding-left: 0;
                    font-weight: 500;
                    li{
                        margin-bottom: 2.7rem;
                        width: 100%;
                    }
                    svg{
                        display: inline-block;
                        stroke: $white;
                        fill: none;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                    }
                    
                    a{
                        display: block;
                        color: $white;
                        text-decoration: none;
                        
                        &:hover{
                            color: $light-gray;
                            text-decoration: underline;
                        }
                    }
                    .arr{
                        line-height: 7.2rem;
                        float: right;
                    }
                    
                    .icon{
                        display: inline-block;
                        width: 10rem;
                        svg{
                            height: 7.2rem;
                        }
                    }
                }
                
            }
        }
            
        &:after{
            top: -10%;
            right: -15rem;
            position: absolute;
            display: block;
            content: " ";
            width: 70rem;
            height: 150%;
            z-index: 50;
            background: rgba(95, 92, 91, 0.8);
            transform: rotate(15deg);
        }
    }
    
    @media (max-height: 840px) and (min-width: 601px){
        .video_content__block__box{
            bottom: 1rem;
        }
    }
    
    @media (max-height: 475px){
        .video_content__block__box{
            overflow: auto;
            height: 100vh
        }
    }
    
    @media (max-width: 1198px){
        video{
            top: 9rem;
            min-width: 100%;
            margin-bottom: 9em;
        }
        @media (max-height: 560px){
            .video_content__block__box{
                overflow: auto;
                height: calc(100vh - 10em);
            }
        }
    }
    
    
    @media (max-width: 600px){
        .video_content{
            position: absolute;
            top: 0;
            right: 0;
            height: 100vh;

            &__block{
                top: 9rem;
                background: rgba(95, 92, 91, 0.8);
                position: relative;
                color: $white;
                text-align: left;
                z-index: 99;
                width: 100%;
                height: calc(100% - 9rem);
                padding: 4rem 6rem;
                
                overflow: auto;
                &__box{
                    overflow: visible;
                    width: 100%;
                    position: inherit;
                    bottom: inherit;
                    right: inherit;
                    padding-right: 0;
                }
            }
            
            &:after{
                display: none;
            }
        }
    }
    @media (max-width: 420px), (max-height: 760px){
        .video_content{
            &__block{
                padding: 2rem 3rem;
                &__box{
                    &__header{
                        font-size: 34px;
                    }
                    &__text{
                        font-size: 1.6rem;
                        margin-bottom: 4.5rem;
                    }
                    &__list{
                        font-size: 1.6rem;
                        line-height: 48px;
                        li{
                            margin-bottom: 2rem;
                        }
                        .icon{
                            svg{
                                height: 48px;
                            }
                        }
                        .arr{
                            line-height: 48px;
                        }
                    }
                }
            }
        }
    }
}
