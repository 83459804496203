.text-with-left-border{
    display: flex;
    &__left-border{
        position: relative;
        background-color:$orange;
        width:3px;
    }
    .container{
        .col-12{
            padding-left:30px;
        }
    }
}