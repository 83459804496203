.main-table{
    position: relative;
    table{
        width:100%;
        thead{
            tr{
                border-bottom:3px solid $orange;
                th{
                    color:$orange;
                    font-weight: bold;
                    font-size:1.6rem;
                    padding:16px;
                    text-align:right;
                    &:nth-child(1){
                        text-align:left;
                    }
                    &:last-child{
                        background-color:$light-gray3;
                    }
                }
            }
        }
        tr{        
            td{
                border-bottom:2px solid $light-gray;
                padding:16px;
                font-size:1.6rem;
                text-align:right;
                &:nth-child(1){
                    text-align:left;
                }
                &:last-child{
                    background-color:$light-gray3;
                    font-weight: bold;
                }
            }
        }
    }    
    .container{
        padding:0;
    }

    &__wrapper{
      overflow-x:auto;
        @media (max-width: 768px){
            margin: 0 auto;
            display: block;
            overflow-x: scroll;
            table{
                width: 600px;
            }
        }
    }
    &__caption{
      text-align: left;
      color: black;
      font-weight: bold;
      font-size: 1.8rem;
      padding: 10px
    }    
}